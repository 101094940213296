import React, { FC } from "react";

import { Helmet } from "react-helmet";

import "@fontsource/poppins/200.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";

export const Layout: FC = ({ children }) => {
  return (
    <>
      <Helmet htmlAttributes={{
        lang: "en",
      }}
      >
        <title>kovalsoft - Under construction</title>
        <meta name="description" content="kovalsoft.com is under construction" />
      </Helmet>
      {children}
    </>
  );
};

export default Layout;
